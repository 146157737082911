/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var Fn = {
    // prevent megamenu Dropdown from Closing
    noClose : function () {
      $(document).on('click', '.dropdown-menu', function (e) {
        e.stopPropagation();
      });
    },
    // Scroll animation
    goTo: function (target, speed, adapt) {
      // Scroll animation
      var toTop =
        typeof adapt !== "undefined" ? $(target).offset().top - $("header.header").height() : $(target).offset().top;
      $("html, body").animate({ scrollTop: toTop }, speed);
    },
    goToAnchor: function (target, speed, add) {
      var _headerHeight = window.innerWidth <= 768 ? 110 : 90;
      if (typeof add === "undefined") {
        add = 0;
      }
      $("html, body").animate(
        {
          scrollTop: $("#" + target).offset().top - _headerHeight + add,
        },
        speed
      );
    }
  };

  var Handlers = {
    download_file: function (fileURL, fileName) {
      // for non-IE
      if (!window.ActiveXObject) {
        var save = document.createElement("a");
        save.href = fileURL;
        save.target = "_blank";
        var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
        save.download = fileName || filename;
        if (
          navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
          navigator.userAgent.search("Chrome") < 0
        ) {
          document.location = save.href;
          // window event not working here
        } else {
          var evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
      }

      // for IE < 11
      else if (!!window.ActiveXObject && document.execCommand) {
        var _window = window.open(fileURL, "_blank");
        _window.document.close();
        _window.document.execCommand("SaveAs", true, fileName || fileURL);
        _window.close();
      }
    },
  };

  var Wishlist = {
    initListeners : function () {
      // Add all items to cart.
      $(document).on("click", "#addtocart-allitems", function (e) {
        e.preventDefault();
        var _favorites = Wishlist.getFavorites('allitems');
        Wishlist.addToCart(_favorites, 'allitems');
      });
      // Add selection to cart.
      $(document).on("click", "#addtocart-selection", function (e) {
        e.preventDefault();
        var _favorites = Wishlist.getFavorites('selection');
        Wishlist.addToCart(_favorites, 'selection');
      });
      // Click on qty button. (all product cards, not only wishlist cards)
      $(document).on("change", ".qty", function (e) {
        e.preventDefault();
        var _this = $(this),
          _product_id = _this.data('product_id'),
          _max = parseInt(_this.attr('max')),
          _qty = _this.val();

        if (_qty > _max) {
          _qty = _max;
          _this.val(_qty);
        }
        Wishlist.updateAddToCartButton(_product_id, _qty);
      });
    },
    getFavorites : function (type) {
      var _checkboxes = $('input[name=favorites]'),
            _favorites = [];
      _checkboxes.each(function () {
        var _checkbox = $(this);
        if (type === 'allitems' || (type === 'selection' && _checkbox.prop('checked'))) {
          _favorites.push(_checkbox.attr('value'));
        }
      });
      // console.log( _favorites );
      return _favorites;
    },
    addToCart : function (items, type) {
      var _loader = (type === 'selection') ? $("#addtocart-selection") : $("#addtocart-allitems"),
          _data = {
            action: "addFavoritesToCart",
            favorites: items,
            type: type
          };
          // si null, add all. sinon, selection

      if (items.length > 0) {
        $.ajax({
          url: Config.ajax_url,
          data: _data,
          type: "POST",
          beforeSend: function (xhr) {
            _loader.addClass("loading");
          },
          success: function (response) {
            console.log(response);
            // TODO
          },
        }); // end Ajax
      }
    },
    updateAddToCartButton : function (_product_id, _qty) {
      var _addtocart_button = $('.ajax_add_to_cart[data-product_id='+_product_id+']');
        _addtocart_button.attr('data-quantity', _qty);
    }
  };

  var Cart = {
    initListeners : function () {
      // Download quote from cart. (pdf)
      $(document).on("click", ".download-quote", function (e) {
        e.preventDefault();
        Cart.downloadCart();
      });

      // Convert cart to quote.
      $(document).on("click", ".save-quote", function (e) {
        e.preventDefault();
        Cart.convertCart();
      });

      // Send cart by email.
      $(document).on("click", ".send-email-cart", function (e) {
        e.preventDefault();
        Cart.sendCartByMail();
      });
    },
    convertCart : function () {
      var _loader = $('.btn'), // tmp
          _data = {
            action: "convertCartToQuote"
          };

      $.ajax({
        url: Config.ajax_url,
        data: _data,
        type: "POST",
        beforeSend: function (xhr) {
          // _loader.addClass("loading");
          $('.save-quote').addClass('loading');
        },
        success: function (response) {
          Cart.refresh_fragments();
          // console.log(response);
          if (response.data.code === 200) {
            $('.save-quote').removeClass('loading').addClass('success');
            document.location = response.data.quote_url;
          }

        },
      }); // end Ajax
    },
    refresh_fragments : function() {
      $( document.body ).trigger( 'wc_fragment_refresh' );
      // console.log('fragments refreshed!');
    }
  };

  var CartPopup = {
    init: function() {
      // Cart desktop
      $(document).on("click", "#cart", function (e) {
        e.preventDefault();
        $(".shopping-cart").fadeToggle( "fast");
      });

      // Cart mobile
      $(document).on("click", "#cart-mobile", function (e) {
        e.preventDefault();
        $(".shopping-cart-mobile").fadeToggle( "fast");
      });
    }
  };

  var Header = {
    init: function() {
      var header = $(".header");
      var toggleClass = "is-sticky";
      var searchBar = $(".ajaxsearchpro");
      var productDetailMenu = $(".product-detail-menu");

      // $( document ).ready(function() {
      //   if ($(window).width() > 576) {
      //     // Hide product detail menu on page load
      //     productDetailMenu.hide();
      //   }
      // });

      $(window).on("scroll", function() {
        var currentScroll = $(this).scrollTop();

        if(currentScroll > 150) {
          if($(window).width() > 576) {
            // Sticky header
            header.addClass(toggleClass);
            // Show product detail menu on scroll
            productDetailMenu.removeClass("d-lg-none");
            productDetailMenu.show();
          }

          if($(window).width() <= 576) {
            searchBar.hide();

            if($(".navbar-collapse").hasClass("show")) {
              $(".navbar-collapse").addClass("active");

              // Check header classes
              if(header.hasClass(toggleClass)) {
                header.removeClass(toggleClass);

                // Hide product detail menu
                productDetailMenu.hide();
              }

            } else {
              $(".navbar-collapse").removeClass("active");
              // Sticky header
              header.addClass(toggleClass);
              // Show product detail menu on scroll
              productDetailMenu.show();
            }
          }

        } else {
          if($(window).width() > 576) {
            header.removeClass(toggleClass);

            // Hide product detail menu
            productDetailMenu.addClass("d-lg-none");
            productDetailMenu.hide();
          }

          if($(window).width() <= 576) {
            searchBar.slideDown();

            if($(".navbar-collapse").hasClass("show")) {
              $(".navbar-collapse").addClass("active");

              // Check header classes
              if(header.hasClass(toggleClass)) {
                header.removeClass(toggleClass);
              }

            } else {
              $(".navbar-collapse").removeClass("active");
              header.removeClass(toggleClass);
            }
          }
        }
      });
    }
  };

  var Menu = {
    init: function() {
      var brands = $(".brands");
      var brandsMenu = $(".brands-menu");
      var products = $(".products");
      var productsMenu = $(".products-menu");
      var support = $(".support");
      var supportMenu = $(".support-menu");

      var cleanMenu = function() {
        $(".menu").each(function(){
          if($(this).hasClass("active")) {
            $(this).removeClass("active");
          }
        });
      };

      // Move showcase on submenu open
      var moveDownShowcase = function() {
        $(".showcase").css("padding-top", "254px");
      };

      // Move showcase on submenu open
      var moveUpShowcase = function() {
        if ($(window).width() > 576 ) {
          $(".showcase").css("padding-top", "200px");
        } else if (!$("body").hasClass("single-product")){
          $(".showcase").css("padding-top", "176px");
        }
      };

      // Show "Brands" menu
      brands.on("click", function(e) {
        e.preventDefault();
        cleanMenu();
        brandsMenu.addClass("active");
        moveDownShowcase();
      });

      // Show "Products" menu
      products.on("click", function(e) {
        e.preventDefault();
        cleanMenu();
        productsMenu.addClass("active");
        moveDownShowcase();
      });

      // Show "Support" menu
      support.on("click", function(e) {
        e.preventDefault();
        cleanMenu();
        supportMenu.addClass("active");
        moveDownShowcase();
      });

      // Close menu on click outside
      $(window).on("click", function(e) {
        if(!e.target.classList.contains("brands") && !e.target.classList.contains("products") && !e.target.classList.contains("support") && $(".megamenu").hasClass("d-none") ) {
          cleanMenu();
          moveUpShowcase();
        }
      });

      $(".navbar-toggler").on("click", function() {
        $(".navbar-collapse").addClass("active");
        $(".navbar-above").hide();
        $(".easy-notification-bar").hide();

        if (!$(".mobile-menu").hasClass("d-block")) {
          $(".mobile-menu").addClass("d-block");
        }
      });
    }
  };

  var Megamenu = {
    init: function() {
      // Show megamenu on "Category" menu item hovered
      $("#category").hover(
          function() {
            $(".megamenu").addClass("active");
            // Show overlay
            $("#overlay").fadeIn();
          }
        );

      // Prevent submenu links from navigating
      $(".collapseMenu, .collapseParent").on("click", function(e) {
        e.stopPropagation();
      });

      // Show submenus on hover
      $(".collapseParent").hover(function(e) {
        // Hide other siblings
        $(this).siblings(".collapseParent").find("#collapseFirst").hide();
        // Show next submenu
        $(this).find("#collapseFirst").show();
        // Remove blue background
        $(this).siblings(".collapseParent").find("a[href='#collapseFirst']").removeClass("active");
      });

      $(".collapseFirst").hover(function() {
        $(this).find("#collapseNext").show();
        // Add blue background
        $(this).parents(".collapseParent").find("a[href='#collapseFirst']").addClass("active");
      });

      $(".collapseNext").hover(function() {
        $(this).find("#collapseLast").show();
        // Add blue background
        $(this).parents(".collapseFirst").find("a[href='#collapseNext']").addClass("active");
      });

      $(".collapseLast").hover(function() {
        // Add blue background
        $(this).parents(".collapseNext").find("a[href='#collapseLast']").addClass("active");
      });

      // If "".noCollapse" hovered hide sibling submenu content
      $(".noCollapse").hover(function() {
        // if level 2
        $(this).siblings(".collapseFirst").find("#collapseNext").hide();
        // if level 3
        $(this).siblings(".collapseNext").find("#collapseLast").hide();
      });

      // Hide megamenu
      $(".megamenu").on("mouseleave", function() {
        // Hide overlay
        $("#overlay").fadeOut();
        // Hide megamenu
        $(".megamenu").removeClass("active");
        // Remove blue backgrounds
        $(".collapseParent").find("a[href='#collapseFirst']").removeClass("active");
        $(".collapseFirst").find("a[href='#collapseNext']").removeClass("active");
        $(".collapseNext").find("a[href='#collapseLast']").removeClass("active");

      });
    }
  };

  var MobileMegamenu = {
    init: function() {
      var categoryLinkMobile = $("#category-link-mobile");
      var firstCollapse = $(".collapseMobileFirst");
      var secondCollapse = $(".collapseMobileSecond");
      var thirdCollapse = $(".collapseMobileThird");
      var closeMenuMobileButton = $(".close-mobile-menu");

      categoryLinkMobile.on("click", function() {
        console.log("category clicked");
        $(".mobile-menu").removeClass("d-block");
        $(".mobile-menu").fadeOut();
        $(".mobile-menu-second").show();
        $(".mobile-menu-second").addClass("active");
      });

      // Return to previous level
      $(".back-level").on("click", function() {

        if ($(".mobile-menu-second").hasClass("active")) {
          if(firstCollapse.hasClass("show") && !secondCollapse.hasClass("show") && !thirdCollapse.hasClass("show")) {
            firstCollapse.removeClass("show");
            $(".mobile-menu-bottom").removeClass("sublevel");
            $(".language-switcher").removeClass("sublevel");
          } else if (secondCollapse.hasClass("show") && !thirdCollapse.hasClass("show")) {
            secondCollapse.removeClass("show");
          } else if (thirdCollapse.hasClass("show")) {
            thirdCollapse.removeClass("show");
          } else {
            $(".mobile-menu-second").removeClass("active");
            $(".mobile-menu-second").hide();
            $(".mobile-menu").addClass("d-block");
            $(".mobile-menu").fadeIn();
          }
        }
      });

      $(".collapseMenuMobile").on("click", function() {
        $(".mobile-menu-bottom").addClass("sublevel");
        $(".language-switcher").addClass("sublevel");
      });

      closeMenuMobileButton.on("click", function() {
        $(".navbar-collapse").removeClass("active");
        $(".navbar-collapse").removeClass("show");
        $(".navbar-above").show();
        $(".easy-notification-bar").hide();
        firstCollapse.removeClass("show");
        secondCollapse.removeClass("show");
        thirdCollapse.removeClass("show");
        $(".mobile-menu-second").hide();
        $(".mobile-menu-bottom").removeClass("sublevel");
        $(".language-switcher").removeClass("sublevel");
      });

    }
  };

  var InputNumber = {
    init: function() {
      // First input created
      (function() {
        window.inputNumber = function(el) {
          function init(el) {
            function decrement() {
              // var min = el.attr('min');
              // var max = el.attr('max');
              var product_id = el.attr('data-product_id');
              var value = el[0].value;
              value--;

              if(value < 1 ) {
                value = 1;
              }
              el[0].value = value;
              Wishlist.updateAddToCartButton(product_id, value);
            }

            function increment() {
              var product_id = el.attr('data-product_id');
              // var min = el.attr('min');
              var max = el.attr('max');
              var value = el[0].value;
              value++;

              if (value > max) {
                value = max;
              }
              el[0].value = value;
              Wishlist.updateAddToCartButton(product_id, value);
            }

            el.prev().on('click', decrement);
            el.next().on('click', increment);
          }

          el.each(function() {
            init($(this));
          });
        };
      })();

      inputNumber($('.input-number'));


      // 2nd input created ( product detail page )
      $(document).ready(function() {
        $('.minus').click(function () {
          var $input = $(this).parent().find('input');
          var count = parseInt($input.val()) - 1;
          count = count < 1 ? 1 : count;
          $input.val(count);
          $input.change();
          return false;
        });
        $('.plus').click(function () {
          var $input = $(this).parent().find('input');
          $input.val(parseInt($input.val()) + 1);
          $input.change();
          return false;
        });
      });
    }
  };

  var Facets = {
    init: function() {
      // Click on specific facets,
      $(document).on("click", ".wpgb-facet", function (e) {
        e.preventDefault();
        if (e.target.tagName !== 'H4') {
          return;
        }

        var _this = $(this),
            _facetId = _this.data('facet'),
            _allowedFacets = [4, 5, 6, 7, 8, 13, 14, 17];

        if (_allowedFacets.includes(_facetId)) {
          var _fieldset = _this.find('fieldset');
          if (_fieldset.hasClass('active')) {
            _fieldset.removeClass("active");
          } else {
            $('.wpgb-facet').find("fieldset").removeClass('active');
            _fieldset.addClass("active");
          }
        }
      });

      // $(document).on("input", "input[name=search-by-name]", function (e) {
      //   e.preventDefault();
      //   var _this = $(this);
      //   console.log('seach by name : ' + _this.val());
      // });
    }
  };

  var Dashboard_Toggler = {
    init: function() {
      if ($(window).width() < 576) {
        // Get references to the menu and toggler button
        var menu = document.querySelector(".fixed-bottom-menu");
        var toggler = document.querySelector(".menu-toggler");
        var closeToggler = document.querySelector(".close-dashboard");


        // Add a click event listener to the toggler button
        if (toggler) {
          toggler.addEventListener("click", function() {
            if (menu.style.display === 'none' || menu.style.display === '') {
                menu.style.display = 'block'; // Show the menu
            } else {
                menu.style.display = 'none'; // Hide the menu
            }
          });

          // Close dashboard
          if (closeToggler) {
            closeToggler.addEventListener("click", function() {
              menu.style.display = 'none'; // Hide the menu
            });
          }
        }

      }
    }
  };

  var Ajax = {
    init : function () {
      // Quotes load more button.
      if ($(".quotes-loadmore").length) {
        $(document).on("click", ".quotes-loadmore", function (e) {
          e.preventDefault();
          var _this = $(this);
          Ajax.loadMore(_this, "loadmoreQuotes", ".quotes-results", _this);
          // console.log('quotes-loadmore');
        });
      }
      // Orders (history) load more button.
      if ($(".orders-loadmore").length) {
        $(document).on("click", ".orders-loadmore", function (e) {
          e.preventDefault();
          var _this = $(this);
          Ajax.loadMore(_this, "loadmoreOrders", ".orders-results", _this);
          // console.log('orders-loadmore');
        });
      }
      // Invoices load more button.
      if ($(".invoices-loadmore").length) {
        $(document).on("click", ".invoices-loadmore", function (e) {
          e.preventDefault();
          var _this = $(this);
          Ajax.loadMore(_this, "loadmoreInvoices", ".invoices-results", _this);
          // console.log('invoices-loadmore');
        });
      }
    },
    loadMore: function (button, ajaxAction, listID, loader) {
      var currentPage = button.attr("data-current-page"),
        maxNumPages = button.attr("data-max-num-pages"),
        exclude = button.attr("data-exclude"),
        categories = button.attr("data-categories"),
        data = {
          action: ajaxAction,
          current_page: currentPage,
          exclude: exclude,
          categories: categories,
          is_ajax: true,
        };

      button.attr("disabled", "disabled");

      $.ajax({
        url: Config.ajax_url,
        data: data,
        type: "POST",
        beforeSend: function (xhr) {
          loader.addClass("active");
        },
        success: function (data) {
          if (data) {
            $(listID).append(data);

            currentPage = parseInt(currentPage) + 1;
            button.attr("data-current-page", currentPage);

            button.removeAttr("disabled");
            loader.removeClass("active");

            if (currentPage >= maxNumPages) {
              button.remove();
            }
          } else {
            button.remove(); // if no data, remove the button as well
          }
        },
      }); // end Ajax
    },
  };

  var Quote = {
    autocomplete_source : Config.quote_products_search_data,
    init: function() {
      Quote.autocomplete();

      // Click on update button.
      $(document).on('click', '.update-address', function (e) {
        e.preventDefault();
        var _this = $(this);
        Quote.toggleForm(_this);
      });

      // Click on close button.
      $(document).on('click', '.close-update-address', function (e) {
        e.preventDefault();
        var _this = $(this);
        Quote.toggleForm(_this);
      });

      // Submit 'update address' form.
      $(document).on('submit', 'form.update-address-ajax', function (e) {
        e.preventDefault();
        var _this = $(this);
        Quote.updateAddress(_this);
      });

      // Click on 'add product' button.
      $(document).on('click', '.btn-add-product-quote', function (e) {
        e.preventDefault();
        var _this = $(this);
        Quote.toggleAddProductForm(_this);
      });

      // Submit 'add product' form.
      $(document).on('submit', 'form.add-products-ajax', function (e) {
        e.preventDefault();
        var _this = $(this),
            _btn = $('button[name="add-product"]');
        if (!_btn.hasClass('disabled')) {
          Quote.addProduct(_this);
        }
      });

      // Submit 'update quantities' form.
      $(document).on('submit', 'form.update-quantities-ajax', function (e) {
        e.preventDefault();
        var _this = $(this);
        Quote.updateQuantities(_this);
      });

      // Click on delete product button.
      $(document).on('click', '.delete-quote-product', function (e) {
        e.preventDefault();
        var _this = $(this);
        Quote.removeProduct(_this);
      });

      // Click on 'Create cart' button.
      $(document).on('click', '.btn-create-cart', function (e) {
        e.preventDefault();
        var _this = $(this);
        Quote.convertQuoteToCart(_this);
      });
    },
    toggleForm: function (btn) {
      var _address_type = btn.data('address'),
          _card_content = $('#'+_address_type+'-address'),
          _card_form = $('#'+_address_type+'-address-form');
          _card_content.toggleClass('d-none');
      _card_form.toggleClass('d-none');
    },
    updateAddress : function (form) {
      // var _form_data = form.serializeArray();
      // console.log( _form_data );

      // TODO : ajax
      var _loader = $('.btn'), // tmp
          _data = {
            action: "updateQuoteAddress",
            id: form.find('#quote_id').val(),
            address_type: form.find('#address_type').val(),
            // data: _form_data
            data: {
              company: form.find('#company').val(),
              address_1: form.find('#address_1').val(),
              address_2: form.find('#address_2').val(),
              postcode: form.find('#postcode').val(),
              city: form.find('#city').val(),
              country: form.find('#country').val()
            }
          };
      $.ajax({
        url: Config.ajax_url,
        data: _data,
        type: "POST",
        beforeSend: function (xhr) {
          // _loader.addClass("loading");
          form.find('button').addClass('loading');
        },
        success: function (response) {
          // console.log(response);
          if (response.data.code === 200) {
            Quote.refreshAddressSection(response.data);
            // Close address form
            $('#'+response.data.type+'-address-form .close-update-address').trigger('click');
            form.find('button').removeClass('loading');
          }
        },
      }); // end Ajax
    },
    refreshAddressSection: function (data) {
      // console.log('refreshAddressSection');
      // Refresh address
      var _section = $('#'+data.type+'-address');
      _section.find('.company').text(data.address.company);
      _section.find('.address_1').text(data.address.address_1);
      _section.find('.address_2').text(data.address.address_2);
      _section.find('.postcode').text(data.address.postcode);
      _section.find('.city').text(data.address.city);
      _section.find('.country').text(Config.countries[ data.address.country ]);
    },
    toggleAddProductForm: function (btn) {
      var _section = $('#add-product-section');
      _section.toggleClass('d-none');
    },
    // addProduct : function (form) {
    //   // console.log('addProduct');
    //   var _selected = form.find(":selected");
    //   // TODO : ajax
    //   var _loader = $('.btn'), // tmp
    //       _data = {
    //         action: "addProductToQuote",
    //         id: form.find('#quote_id').val(),
    //         data: {
    //           product_id: _selected.val(),
    //           variation_id: _selected.data('variation-id'),
    //         }
    //       };
    //   $.ajax({
    //     url: Config.ajax_url,
    //     data: _data,
    //     type: "POST",
    //     beforeSend: function (xhr) {
    //       // _loader.addClass("loading");
    //     },
    //     success: function (response) {
    //       // console.log(response);
    //       if (response.data.code === 200) {
    //         // Refresh products list
    //         $('.nb-items-ordered').empty().html(response.data.products_html);
    //         // Refresh totals
    //         $('.quote-totals').empty().html(response.data.totals_html);
    //       }
    //     },
    //   }); // end Ajax
    // },
    addProduct : function (form) {
      // console.log('addProduct');
      var _loader = $('.btn'),
          _data = {
            action: "addProductToQuote",
            id: form.find('#quote_id').val(),
            data: {
              product_id: $('#add-product-section #search-product-id').val(),
              variation_id: $('#add-product-section #search-variation-id').val(),
            }
          };
      $.ajax({
        url: Config.ajax_url,
        data: _data,
        type: "POST",
        beforeSend: function (xhr) {
          // _loader.addClass("loading");
          form.find('button').addClass('loading');
        },
        success: function (response) {
          // console.log(response);
          if (response.data.code === 200) {
            // Refresh products list
            $('.nb-items-ordered').empty().html(response.data.products_html);
            // Refresh totals
            $('.quote-totals').empty().html(response.data.totals_html);
            // Reset 'add product' form.
            Quote.resetAddProductButton();
            // Refresh autocomplete source.
            $("#add-product-section #autocomplete").autocomplete('option', 'source', response.data.autocomplete_source);
            // Refresh input listeners
            InputNumber.init();
            form.find('button').removeClass('loading');
          }
        },
      }); // end Ajax
    },
    updateQuantities : function (form) {
      // console.log('updateQuantities');

      var _products = form.find('.product-ordered'),
          _products_data = [];
      _products.each(function () {
        var _item = $(this).find('.input-number'),
            _item_id = _item.data('product_id'),
            _item_variation_id = _item.data('variation_id'),
            _item_qty = _item.val();
        // console.log('item : ' + _item_id + ' (var: ' + _item_variation_id + '), qty : ' + _item_qty);

        _products_data.push({
          product_id: _item_id,
          variation_id: _item_variation_id,
          qty: _item_qty,
        });
      });
      // console.log(_products_data);

      var _loader = $('.btn'), // tmp
          _data = {
            action: "updateQuoteQuantities",
            id: form.find('#quote_id').val(),
            data: {
              products: _products_data
            }
          };
      $.ajax({
        url: Config.ajax_url,
        data: _data,
        type: "POST",
        beforeSend: function (xhr) {
          // _loader.addClass("loading");
          form.find('button').addClass('loading');
        },
        success: function (response) {
          // console.log(response);
          if (response.data.code === 200) {
            // Refresh products list
            $('.nb-items-ordered').empty().html(response.data.products_html);
            // Refresh totals
            $('.quote-totals').empty().html(response.data.totals_html);
            // Refresh input listeners
            InputNumber.init();
            form.find('button').removeClass('loading');
          }
        },
      }); // end Ajax
    },
    autocomplete: function () {
      // SEARCH
      var search = $("#add-product-section #autocomplete"),
          submit_btn = $('button[name="add-product"]');
      search
        .autocomplete({
          minLength: 0,
          source: Quote.autocomplete_source,
          focus: function (event, ui) {
            return false;
          },
          select: function (event, ui) {
            search.val(ui.item.label + " - #" + ui.item.value);
            $("#add-product-section #search-product-id").val(
              ui.item.value
            );
            $("#add-product-section #search-variation-id").val(
              ui.item.variation_id
            );
            submit_btn.removeClass('disabled');
            return false;
          },
          search: function ( event, ui ) {
            submit_btn.addClass('disabled');
          },
        })
        .autocomplete("instance")._renderItem = function (ul, item) {
          return $("<li>")
            .append(
              "<div class='autocomplete-product-item'>" +
              item.label +
              " <span> - #" + item.value + "</span></div>" +
              " </div>"
            )
            .appendTo(ul);
        };
        // END SEARCH
    },
    resetAddProductButton: function () {
      // Reset 'add product' form.
      $('button[name="add-product"]').addClass('disabled');
      $("#add-product-section #autocomplete").val('');
      $("#add-product-section #search-product-id").val('');
      $("#add-product-section #search-variation-id").val('');
    },
    removeProduct : function (form) {
      // console.log('addProduct');
      var _loader = $('.btn'),
          _data = {
            action: "removeProductToQuote",
            id: form.data('quote-id'),
            data: {
              product_id: form.data('product-id'),
              variation_id: form.data('variation-id'),
            }
          };
      $.ajax({
        url: Config.ajax_url,
        data: _data,
        type: "POST",
        beforeSend: function (xhr) {
          // _loader.addClass("loading");
        },
        success: function (response) {
          // console.log(response);
          if (response.data.code === 200) {
            // Refresh products list
            $('.nb-items-ordered').empty().html(response.data.products_html);
            // Refresh totals
            $('.quote-totals').empty().html(response.data.totals_html);
            // Reset 'add product' form.
            Quote.resetAddProductButton();
            // Refresh autocomplete source.
            $("#add-product-section #autocomplete").autocomplete('option', 'source', response.data.autocomplete_source);
            // Refresh input listeners
            InputNumber.init();
          }
        },
      }); // end Ajax
    },
    convertQuoteToCart: function (button) {
      var _loader = $('.btn'),
          _data = {
            action: "convertQuoteToCart",
            id: button.data('quote-id'),
            data: {}
          };
      $.ajax({
        url: Config.ajax_url,
        data: _data,
        type: "POST",
        beforeSend: function (xhr) {
          button.addClass('loading');
        },
        success: function (response) {
          // console.log(response);
          if (response.data.code === 200) {
            button.removeClass('loading');
            document.location = response.data.location;
          }
        },
      }); // end Ajax
    }
  };

  var Quotes = {
    init: function() {
      // Click on 'Duplicate' button.
      $(document).on('click', '.btn-duplicate', function (e) {
        e.preventDefault();
        var _this = $(this),
            _quote_id = _this.closest('.deal-details').data('quote-id');
        Quotes.duplicate(_quote_id);
      });

      // Click on 'Delete' button.
      $(document).on('click', '.btn-delete', function (e) {
        e.preventDefault();
        var _this = $(this),
            _quote_id = _this.closest('.deal-details').data('quote-id');
        Quotes.delete(_quote_id);
      });
    },
    duplicate: function (id) {
      var _loader = $('.btn'),
          _data = {
            action: "duplicateQuote",
            id: id
          };
      $.ajax({
        url: Config.ajax_url,
        data: _data,
        type: "POST",
        beforeSend: function (xhr) {
          // _loader.addClass("loading");
          $('.btn-duplicate').addClass('loading');
        },
        success: function (response) {
          // console.log(response);
          if (response.data.code === 200) {
            $('.btn-duplicate').removeClass('loading').addClass('success');
            document.location = response.data.new_quote;
            // TODO : refresh quotes list ?
          }
        },
      }); // end Ajax
    },
    download: function(id) {
      console.log('download : #' + id);
    },
    delete: function (id) {
      var _loader = $('.btn'),
          _data = {
            action: "deleteQuote",
            id: id
          };
      $.ajax({
        url: Config.ajax_url,
        data: _data,
        type: "POST",
        beforeSend: function (xhr) {
          // _loader.addClass("loading");
          $('.btn-delete').addClass('loading');
        },
        success: function (response) {
          // console.log(response);
          if (response.data.code === 200) {
            $('.btn-delete').removeClass('loading').addClass('success');
            document.location = response.data.location;
            // TODO : refresh quotes list ?
          }
        },
      }); // end Ajax
    },
  };

  var B2B = {
    init: function () {
      // Click on 'Terms & conditions' checkbox.
      $( "#termsAndConditionsB2B" ).on( "click", function (e) {
        var _this = $(this),
            _submit = $('.btn-b2b-registration');
        // console.log('click checkbox : ' + _this.prop('checked'));
        if (_this.prop('checked')) {
          _submit.removeClass('disabled');
        } else {
          _submit.addClass('disabled');
        }
      });
    }
  };

  var Account = {
    init: function () {
      // Click on 'customize' button.
      $( ".customize-btn" ).on( "click", function (e) {
        e.preventDefault();
        var _this = $(this),
            _section = _this.data('section');
        Account.toggleSection(_section);
      });
      // Click on 'close' button.
      $( ".close-btn" ).on( "click", function (e) {
        e.preventDefault();
        var _this = $(this),
            _section = _this.data('section');
        Account.toggleSection(_section);
      });

      // After country select change.
      $('#country').on('change', function (e) {
        var _this = $(this),
            _selectedCountry = _this.find("option:selected").val(),
            _states = Config.states[ _selectedCountry ],
            _stateLabel = $('label[for=state] span'),
            _stateInput = $('input[name=state]');

        // Add or remove required attribute.
        if ($.isEmptyObject( _states )) {
          _stateLabel.addClass('d-none');
          _stateInput.removeAttr('required');
        } else {
          _stateLabel.removeClass('d-none');
          _stateInput.prop('required',true);
        }
      });
    },
    toggleSection: function(section_id) {
      $('#'+section_id).toggleClass('d-none');
      $('#'+section_id+'-form').toggleClass('d-none');
    }
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        window.WebFontConfig = {
          // google: {
          //   families: [ 'Oswald:300,400,600', 'Work+Sans:300,400,500,600,700' ]
          // },
          typekit: {
            id: 'jwx2iiz'
          }
        };
        (function() {  var wf = document.createElement('script');
          wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
          wf.type = 'text/javascript';  wf.async = 'true';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(wf, s);
        })();
        Fn.noClose();
        Header.init();
        Menu.init();
        InputNumber.init();
        Facets.init();
        Dashboard_Toggler.init();
        CartPopup.init();
        Megamenu.init();
        MobileMegamenu.init();
        Ajax.init();

        $(".product-detail-link").on("click", function(e) {
          e.preventDefault();
          var _anchorC = $(this).attr("href");
          Fn.goTo(_anchorC, 1000, true);
        });

        // Download product instructions file.
        $(".dl-button").click(function (e) {
          e.preventDefault();
          var _fileUrl = $(this).attr("href"),
            _fileName = $(this).data("file-name");
          Handlers.download_file(_fileUrl, _fileName);
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        new Swiper(".hero-slider", {
          loop: true,
          grabCursor: true,
          slidesPerView: 1,
          spaceBetween: 16,
          pagination: {
            el: ".swiper-pagination",
          },
        });

        new Swiper(".cards-slider", {
          slidesPerView: "auto",
          spaceBetween: 16,
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // My Account
    'woocommerce_account': {
      init: function() {
        Wishlist.initListeners();
        // Display coupon code after click on 'Use promotion' button.
        $(document).on("click", ".btn-use-promo", function (e) {
          e.preventDefault();
          var _this = $(this),
              _coupon_id = _this.data("coupon-id");
          $('.btn-label').addClass('d-none');
          $('#'+_coupon_id).removeClass('d-none');
        });

        // Slider "orders recently consulted"
        var swiper2 = new Swiper('.swiper-consulted', {
          // Optional parameters
          direction: 'horizontal',
          slidesPerView: 3,
          spaceBetween: "30px",
          grabCursor: "true",

          // Responsive breakpoints
          breakpoints: {
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 15
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 1,
              spaceBetween: 15
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          },

          // Navigation arrows
          navigation: {
            nextEl: '.swiper-button-next.orders',
            prevEl: '.swiper-button-prev.orders',
          },
        });

        // Slider "orders recently purchased"
        var swiper3 = new Swiper('.swiper-purchased', {
          // Optional parameters
          direction: 'horizontal',
          slidesPerView: 3,
          spaceBetween: "30px",
          grabCursor: "true",

          // Responsive breakpoints
          breakpoints: {
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 15
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 1,
              spaceBetween: 15
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          },

          // Navigation arrows
          navigation: {
            nextEl: '.swiper-button-next.purchases',
            prevEl: '.swiper-button-prev.purchases',
          },
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'account': {
      init: function() {
        Account.init();
      }
    },

    // Contact us
    'contact_us': {
      init: function() {
        // Slider "3D Storm events"
        var swiper = new Swiper('.swiper-events', {
          // Optional parameters
          direction: 'horizontal',
          slidesPerView: 3,
          spaceBetween: "30px",
          grabCursor: "true",

          // Responsive breakpoints
          breakpoints: {
            // when window width is >= 320px
            320: {
              slidesPerView: 2,
              spaceBetween: 15
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 2,
              spaceBetween: 15
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          },

          // Navigation arrows
          navigation: {
            nextEl: '.swiper-button-next.events',
            prevEl: '.swiper-button-prev.events',
          },
        });
      }
    },

    'woocommerce_cart': {
      init: function() {
        // Wishlist.initListeners();
        Cart.initListeners();

        if ($(window).width() < 576 ) {
          $(".menu-toggler").on("click", function() {
            $(".fixed-bottom-menu").show();
          });
        }

        var updateBtn = $("#update-button");
        var btnUpdate = $(".btn-update");

        // Check if Update button is disabled
        function checkAriaDisabled() {
          // Get the value of the aria-disabled attribute
          var ariaDisabledValue = updateBtn.attr("aria-disabled");

          // Check if the attribute exists and its value is "true"
          if (ariaDisabledValue && ariaDisabledValue === "true") {
              // Disable update button
              btnUpdate.attr("aria-disabled", "true");
          } else {
              // Enable update button
              btnUpdate.addClass("active");
            }
        }

        // Call the checkAriaDisabled function every second
        setInterval(checkAriaDisabled, 1000);

        $(document).on("click", "#update-button", function () {
          updateBtn.attr("aria-disabled", "true");
        });

        // Click on qty button
        $(document).on("change", ".qty", function () {
          btnUpdate.attr("aria-disabled", "false");
          $(".btn-update").addClass("active");

          // Call the checkAriaDisabled function every second
          setInterval(checkAriaDisabled, 1000);
        });


        $(document).on("click", ".btn-update", function (e) {
          e.preventDefault();
          $("#update-button").click();
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // Dashboard
    'dashboard': {
      init: function() {
        // Slider "news & offers"
        var swiper = new Swiper('.swiper-news', {
          // Optional parameters
          direction: 'horizontal',
          slidesPerView: 3,
          spaceBetween: "30px",
          grabCursor: "true",

          // Responsive breakpoints
          breakpoints: {
            // when window width is >= 320px
            320: {
              slidesPerView: 2,
              spaceBetween: 15
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 2,
              spaceBetween: 15
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          },

          // Navigation arrows
          navigation: {
            nextEl: '.swiper-button-next.news',
            prevEl: '.swiper-button-prev.news',
          },
        });
      }
    },

    'benefits': {
      init: function() {
        $(".woocommerce-MyAccount-navigation-link--dashboard").addClass("is-active");
      }
    },

    'quote': {
      init: function() {
        Quote.init();
      }
    },

    'quotes_orders_invoices': {
      init: function() {
        Quotes.init();
      }
    },

    'page_template_template_registration': {
      init: function() {
        B2B.init();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
